import React from "react"
import { Link } from "gatsby"

import Layout from "src/components/layout"
import SEO from "src/components/seo"
import PageTitle from "src/components/page-title"

import busyFounders from "src/images/books/busy-founders/book-cover-pages@2x.png"
import map from "src/images/books/busy-founders/map.svg"
import sweetSpot from "src/images/books/busy-founders/sweet-spot.svg"
import cycle from "src/images/books/busy-founders/cycle.svg"

export default function GuideToUserOnboarding() {
  return (
    <Layout>
      <SEO
        title={"The Busy Founder's Guide to User Onboarding"}
        description={
          "Written by founders, for founders – how to master user onboarding in your company. Download free today!"
        }
      />
      <main class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32 pb-24">
        <div class="lg:grid lg:grid-cols-12 lg:gap-8">
          <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h1>
              <span class="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                Simple. Actionable. Authoritative.
              </span>
              <span class="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                <span class="block text-gray-900">User Onboarding:</span>
                <span class="block text-oOrange">The Busy Founder's Guide</span>
              </span>
            </h1>
            <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              Learn how to reach growth, revenue and success through better
              customer experience with the user onboarding ebook.
            </p>
            <div class="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
              <p class="text-base font-medium text-gray-900">
                Enter your email and we'll send it right now:
              </p>
              <form
                name="guideToUserOnboarding"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                method="POST"
                class="mt-3 sm:flex"
              >
                <input
                  type="hidden"
                  name="form-name"
                  value="guideToUserOnboarding"
                />
                <label for="email" class="sr-only">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  class="block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1 border-gray-300"
                  placeholder="Enter your email"
                />
                <button
                  type="submit"
                  class="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                >
                  Send It
                </button>
              </form>
            </div>
          </div>
          <div class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div class="relative mx-auto w-full lg:max-w-md">
              <img class="w-full" src={busyFounders} alt="" />
            </div>
          </div>
        </div>
        <div class="w-full my-24">
          <h2 class="mt-3 text-gray-900 font-extrabold text-gray-500 sm:mt-5 sm:text-2xl lg:text-3xl xl:text-4xl text-center">
            What's inside?
          </h2>
        </div>
        <div class="lg:grid lg:grid-cols-12 lg:gap-x-8 gap-y-36">
          <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
              Understanding the importance of user onboarding
            </h2>
            <p class="mt-4 text-lg text-gray-500">
              What separates a happy customer from a churned one? How can you
              make sure that your company’s prospects succeed and convert,
              rather than giving up and moving on?
            </p>
            <p class="mt-4 text-lg text-gray-500">
              "User onboarding is the process of increasing the likelihood that
              new users become successful when adopting your product," says
              Samuel Hulick. We've spent five years studying this phenomenon,
              and now we're compiling everything we've learned in this
              easy-to-use guide on how do do things better.
            </p>
            <p class="mt-4 text-lg text-gray-500">
              We'll examine how good onboarding helps businesses move visitors,
              prospects and clients towards success - and how getting it right
              has played a pivotal role in the winning strategies of Silicon
              Valley titans such as Twitter, Facebook and LinkedIn.
            </p>
          </div>
          <div class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <img class="w-full" src={map} alt="" />
          </div>
          <div class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <img class="w-full" src={sweetSpot} alt="" />
          </div>
          <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
              How to lay the foundations for user onboarding success
            </h2>
            <p class="mt-4 text-lg text-gray-500">
              What best practices do companies need to ensure that every user
              has a great onboarding experience, every time?
            </p>
            <p class="mt-4 text-lg text-gray-500">
              Using a handy checklist covering the organisational and
              technological requirements for better onboarding, we'll work out
              how any company can build an infrastructure that drives the
              success of new customers - increasing happiness, increasing
              conversion rates, and decreasing churn.
            </p>
            <p class="mt-4 text-lg text-gray-500">
              Plus, get our user onboarding teardowns, examples and best
              practice guides for ensuring that you're offering the best
              experience possible for all of your users.
            </p>
          </div>
          <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
              Practical ways to help customers succeed
            </h2>
            <p class="mt-4 text-lg text-gray-500">
              How should modern web businesses inspire their customers to
              succeed, and remove the practical steps that are standing in the
              way of them doing so?
            </p>
            <p class="mt-4 text-lg text-gray-500">
              Using a host of best practices inspired by UX design and
              psychology, we'll design and implement better user onboarding
              flows that simultaneously nudge customers down the right path and
              dramatically reduce the time that it takes them to reach success.
            </p>
          </div>
          <div class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <img class="w-full" src={cycle} alt="" />
          </div>
        </div>
      </main>
    </Layout>
  )
}
